<template>
  <auth-layout v-if="access_token == null">
    <!--  Registration Form  -->
    <registration-form />

    <div class="register-link font-size-sm">
      {{ $t("auth.register.body") }}
      <router-link to="/login">{{ $t("auth.register.login") }}</router-link>
    </div>

  </auth-layout>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import AuthLayout from "./partials/AuthLayout";
import RegistrationForm from "@/views/Auth/partials/RegistrationForm";
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n";

export default {
  setup() {
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("auth.register.page_title"),
    })
  },
  components: {RegistrationForm, AuthLayout},
  name: "Register",
  data: () => {
    return {
      awaiting_register_api: false,
      formData: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  methods: {
    ...mapActions(["authRegister"]),
    register: function () {
      this.awaiting_register_api = true;
      this.authRegister({
        name: this.formData.name,
        email: this.formData.email,
        password: this.formData.password,
        password_confirmation: this.formData.password_confirmation,
      }).finally(() => {
        this.awaiting_register_api = false;
      });
    },
  },
  computed: {
    ...mapGetters(["access_token"]),
  },
};
</script>

<style lang="scss">

</style>
