<template>
  <Form v-on:submit="register">
    <div class="mb-2">
      <Field
          name="register-name"
          rules="required"
          class="form-control"
          type="text"
          v-model="formData.name"
          v-bind:placeholder="$t('auth.name')"
      />
      <validation-error name="register-name"/>
      <ErrorMessage name="register-name"/>
    </div>

    <div class="mb-2">
      <Field
          name="register-email"
          rules="required|email"
          class="form-control"
          type="text"
          v-model="formData.email"
          v-bind:placeholder="$t('auth.email')"
      />
      <validation-error name="register-email"/>
      <ErrorMessage name="register-email"/>
    </div>

    <div class="mb-2">
      <Field
          name="register-password"
          rules="required|min:6"
          class="form-control"
          type="password"
          v-model="formData.password"
          v-bind:label="$t('auth.password')"
          v-bind:placeholder="$t('auth.password')"
      />
      <validation-error name="register-password"/>
      <ErrorMessage name="register-password"/>
    </div>

    <div class="mb-2">
      <Field
          name="register-password-confirmation"
          rules="required|min:6|confirmed:@register-password"
          class="form-control"
          type="password"
          v-model="formData.password_confirmation"
          v-bind:label="$t('auth.password')"
          v-bind:placeholder="$t('auth.password')"
      />
      <validation-error name="register-password-confirmation"/>
      <ErrorMessage name="register-password-confirmation"/>
    </div>

    <button class="submit-btn btn btn-primary" type="submit" :disabled="awaiting_register_api">{{$t("auth.register.head")}}</button>
  </Form>
</template>

<script>
import ValidationError from "@/components/ValidationError";
import {ErrorMessage, Field, Form} from "vee-validate";
import {mapActions} from "vuex";

export default {
  name: "RegistrationForm",
  components: {ValidationError, Field, Form, ErrorMessage},
  data: () => {
    return {
      awaiting_register_api: false,
      formData: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  methods: {
    ...mapActions(["authRegister"]),
    register: function () {
      this.awaiting_register_api = true;
      this.authRegister({
        name: this.formData.name,
        email: this.formData.email,
        password: this.formData.password,
        password_confirmation: this.formData.password_confirmation,
      }).then(() => {
        this.$emit("registrationAttempt", true);
      }, () => {
        this.$emit("registrationAttempt", false);
      }).finally(() => {
        this.awaiting_register_api = false;
      });
    },
  }
}
</script>

<style scoped>

</style>
